<template>
  <div>
      <CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader class="">
      <CRow>
      <CCol col="6" xl="6">
        <h6 class="w-100">Hromadná úprava</h6>
        <h1 class="w-100">{{thistype}}</h1>
      </CCol>
      <CCol col="6" xl="6">

      </CCol>
      </CRow>
      </CCardHeader>
      <CCardBody>
                  <div class="card shadow p-4">
<template v-for="node,nid in data">
<a-row class="node" :key="nid">
<a-col :span="24"><h3>{{ node.title }}</h3></a-col>
  <template v-for="field in allfields">
  <template v-if="field.type == '5'">
      <a-col :span="3" class="p-1">
          <a-form-item :label="field.label">
      <a-input-number alternative="" class="w-100" autofocus   input-classes="form-control-alternative" type="number"  v-model="node[field.value]"/>
          </a-form-item>
  </a-col>
  </template>
  <template v-if="field.type == '6'">
      <a-col :span="3" class="p-1">
          <a-form-item :label="field.label">
      <a-input-number alternative="" class="w-100" autofocus   input-classes="form-control-alternative" type="number"  :step=".1" v-model="node[field.value]"/>
          </a-form-item>
  </a-col>
  </template>
  <template v-if="field.type == '1'">
      <a-col :span="3" class="p-1">
          <a-form-item :label="field.label">
      <a-input alternative="" autofocus  :label="field.label"  input-classes="form-control-alternative" v-model="node[field.value]"/>
          </a-form-item>
  </a-col>
  </template>
  <template v-if="field.type == '4'">
      <a-col :span="6" class="p-1">
            <label class="form-control-label">{{field.title}}</label>
             <ckeditor :editor="editor" v-model="node[field.value]"  :config="editorConfig"></ckeditor>

  </a-col>
  </template>
  <template v-if="field.type == '7'">
      <a-col :span="3" class="p-1">
          <a-form-item :label="field.label">
           <a-checkbox alternative="" autofocus  :label="field.label"  input-classes="form-control-alternative" v-model="node[field.value]"/>
          </a-form-item>
  </a-col>
  </template>
  </template>
</a-row>
</template>
<div class="mb-4">

<div class="text-right">
  <a-button type="secondary" class="mr-3" @click="bulkupdateModal = true">Hromadné hodnoty</a-button>
<button class="btn btn-primary" @click="bulkupdate"><span class="btn-inner--icon" style="margin-right:5px"><i class="fad fa-save pr-1"></i></span>Hromadne uložiť</button>
</div>
</div>
<a-modal title="Hromadné hodnoty" cancelText="Zrušiť" @cancel="bulkupdateModal = false" centered v-model="bulkupdateModal" v-if="bulkupdateModal" okText="Priradiť" @ok="assignData">
  <a-row :key="hii">
      <template v-for="field in allfields">
  <template v-if="field.type == '1'">
      <a-col>
          <a-form-item :label="field.label">
      <a-input alternative="" autofocus  :label="field.label"  input-classes="form-control-alternative"  v-model="bulkUpdateFields[field.value]"/>
          </a-form-item>
  </a-col>
  </template>
  <template v-if=" field.type == '5'">
      <a-col>
          <a-form-item :label="field.label">
      <a-input-number alternative="" autofocus  :label="field.label"  input-classes="form-control-alternative"  v-model="bulkUpdateFields[field.value]"/>
          </a-form-item>
  </a-col>
  </template>
  <template v-if="field.type == '6'">
      <a-col>
          <a-form-item :label="field.label">
      <a-input-number alternative="" autofocus  :label="field.label"  input-classes="form-control-alternative"  :step=".1" v-model="bulkUpdateFields[field.value]"/>
          </a-form-item>
  </a-col>
  </template>
  <template v-if="field.type == '7'">
      <a-col>
          <a-form-item :label="field.label">
      <a-checkbox alternative="" autofocus @change="hii++" :label="field.label"  input-classes="form-control-alternative"  v-model="bulkUpdateFields[field.value]"/>
          </a-form-item>
  </a-col>
  </template>

  <template v-if="field.type == '4'">
      <a-form-item :label="field.label">
             <ckeditor :editor="editor" v-model="bulkUpdateFields[field.value]"  :config="editorConfig"></ckeditor>
          </a-form-item>
  </template>

  </template>
</a-row>

      </a-modal>

                  </div>
                  </CCardBody>
                  </CCard>

  </CCol>
      </CRow>
  </div>
</template>
<script>
  import {getURL} from "../api";
   import {dataService} from "../_services";
  import Vue from "vue"
  import FormItem from 'ant-design-vue'
  import moment from 'moment';
  import AInput from "ant-design-vue/es/input";
  import {authHeader} from "../_helpers";
  import locale from 'ant-design-vue/es/date-picker/locale/sk_SK';
  import {IMG_URL} from "../api";
  import {Button,Select,Form,Dropdown, Row,Col,Modal,Upload, Popconfirm,Popover,Tag,Carousel} from 'ant-design-vue';
  Vue.use(FormItem);
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


  import '@ckeditor/ckeditor5-build-classic/build/translations/sk';
  import draggable from "vuedraggable";



  export default {
      name: "allitems",
      components: {
          "a-button": Button,
          "a-select": Select,
          "a-popconfirm": Popconfirm,
          "a-popover": Popover,
          "a-carousel": Carousel,
          draggable,
          "a-upload": Upload,
            "a-tag": Tag,
            AInput,
            "a-dropdown": Dropdown,
            "a-row":Row,
            "a-col":Col,
            "a-modal":Modal,
      },
      data() {
          return {
              data: [],
              vars:{},
              colorsto: JSON.parse(localStorage.colors),
             url: getURL(),
              ctt: [],
              loading: false,
              refreshtable:0,
              head: authHeader(),
              contenttypes:[],
              locale,
              moment,
              languages:[],
              bulkdatas:false,
              defaultLanguage:"",
              allItems:[],
              hii:0,
              reorder:false,
              pagination: {
                  hideOnSinglePage: false,
                  pageSize: 50, // default number of pages per page
                  showSizeChanger: true, // display can change the number of pages per page
                  pageSizeOptions: ['10', '20', '30','50','100','300','1000'], // number of pages per option
                  showTotal: total => `Položiek: ${total}`, // show total
                  showSizeChange: (current, pageSize) => {  this.pageSize = pageSize;  }, // update display when changing the number of pages per page
              },
              fieldtitle:"",
              visible: false,
              allowreorder: false,
              slugs:[],
              filterBy:[],
              realFilter:JSON.parse(localStorage.filter),
              perm:JSON.parse(localStorage.user).data.paths,
              filterValues:{},
              actions: [{"value":"delete","label":"Zmazať"},{"value": "export","label": "export"},{"value": "publish","label": "publikovať / odpublikovať"},{"value": "multiedit","label": "hromadná úprava"}],
              thistype:"",
              selectedRowKeys: [],
              action:undefined,
              actionFields:[],
              allfields:[],
              selectValue:[],
              bulkupdateModal:false,
              bulkUpdateFields:{},
              IMG_URL,
              filterAll:{},
              maincategory:null,
              slugfields:[],
              filteredInfo: null,
              justThese:[],
              sortedInfo: null,
              showon: [],
              editor: ClassicEditor,
              value: [],
              newTax: {},
              editorConfig: {
                  allowedContent: true,
                  heading: {
                      options: [
                          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                          { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                          { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                      ]
                  },
                  toolbar: [ 'heading', '|', 'sourceEditing', 'bold', 'italic','strikethrough','underline', 'link', 'selectAll',  'bulletedList', 'numberedList', 'alignment', '|', 'insertTable', 'superscript','|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo', '|' , 'HtmlEmbed' ],
                  table: {
                      toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                  },
                  htmlSupport: {
                      allow: [
{
name: 'iframe',
attributes: true,
classes: true,
styles: true
}
]
      },
      htmlEmbed: {
          showPreviews: true
      },
                  extraPlugins: [this.uploader,],
                  language: 'sk',
              },
          };
      },
      computed: {
          filterProductsByName: function() {


              this.justThese = [];
              return this.data.filter(product => {
                  let i = 0;
                  var removefields = 0;


                 for (const [slug, value] of Object.entries(this.realFilter)) {

                     if(!value.value)
                     this.removeFilter(slug);

                      if(this.filterBy[value.slug])
                      {
                     if((value.value != "-" && value.value != "notassigned") && product[value.slug] !== null)
                     {
                     if(value.type === 12)
                     {


                      for (const [slugf, valuef] of Object.entries(this.filterBy[value.slug].data)) {

                          if(valuef.kids)
                          {
if(valuef.value === value.value){


                              if(valuef.kids.includes(product[value.slug]))
                              {

                             i = i + 1;

                              }
                          }}
                      }


                         if(product[value.slug].includes(value.value) || product[value.slug] === value.value )
                         {
                             i = i + 1;
                             //   this.justThese.push(product.id);
                         }

                     }
                     else {
                        let compareto = value.value;
                       if(value.value == "true")
                       compareto = true;
                       if(value.value == "false")
                       compareto = false;
                       if(product[value.slug] === compareto)
                      {
                          i = i + 1;
                           //  this.justThese.push(product.id);
                      }
                     }
                     }
                     else {
if(value.type === 7 && (value.value == "notassigned") && (product[value.slug] === null || product[value.slug] === ""))
{i = i + 1;
// this.justThese.push(product.id);
 }
if(value.type === 7 && (value.value == "-" && (product[value.slug] === false || product[value.slug] === null || product[value.slug] === "" || product[value.slug] === true)))
{i = i + 1;   //this.justThese.push(product.id);
}
if(value.type === 12 && (value.value == "-"))
{i = i + 1;  // this.justThese.push(product.id);
}
if(value.type === 12 && (value.value == "notassigned" && (product[value.slug] === false || product[value.slug] === null || product[value.slug] === "" || product[value.slug] === true)))
{i = i + 1;  // this.justThese.push(product.id);
}
                        // removefields = removefields + 1;
                     }
                  }
                  else
                  {
                     removefields = removefields + 1;
                  }
                  var size = 0 - removefields, key;
                  for (key in this.realFilter) {
                      if (this.realFilter.hasOwnProperty(key)) size++;
                  }  }



                  this.pagination.total
if(this.realFilter.length == 0)
{
  this.justThese.push(product.id);
  return true;
}
                  if(size === i)
                  {
                      this.justThese.push(product.id);
                      return true;}
                  else
                  return false
              })


          },
          rowSelection() {
              const { selectedRowKeys } = this;
              return {
                  selectedRowKeys,
                  onChange: this.onSelectChange,
                  hideDefaultSelections: true,
                  onSelection: this.onSelection,
              };
          },
      },
      mounted() {
this.loading = true;

        dataService.axiosFetch("variable/site_url").then(results => {
                  this.vars = results;
              })
dataService.axiosFetch("contenttypes").then(results => {
                  this.contenttypes = results;

                  results.forEach(obj => {
                      var iid = obj.id;
                      var vval = obj.title;
                      this.ctt.push({"text" : vval,"value" : iid});
                      if(obj.filters)
                      this.filterAll[iid] = obj.filters;


                      if(obj.slug === this.id)
                      { console.log(obj.slug,this.id)
                          this.thistype = obj.title;

                        if(obj.filters)
                        {
                          this.filterBy = obj.filters;
                          let ke = Object.keys(obj.filters);
                          ke.forEach(j => {
                              this.filterValues[j] = "";
                          });
                         }


                           if(obj.allfields)
                        {

                          obj.allfields.forEach(obj => {

                          if(obj.type == "1" || obj.type == "5" || obj.type == "6" || obj.type == "7" || obj.type == "4")
                          {
                              if(this.acfields.includes(obj.slug))
                              {
                                  this.allfields.push({"value":obj.slug,"label":obj.title,"type":obj.type});
                                if(obj.type == "7")
                                this.bulkUpdateFields[obj.slug] = false;
                                else
                                this.bulkUpdateFields[obj.slug] = "";
                              }
                          }

                          })
                        }



                          if(obj.fields)
                          {

                              this.columns = obj.fields;
                              this.columns.push(
                                {
                                      title: 'Vytvorené',
                                      dataIndex: 'created',
                                      scopedSlots: {customRender: 'datetime'},
                                      onFilter: (value, record) => record.name.indexOf(value) === 0,
                                      sorter: (a, b) => a.created - b.created,
                                      defaultSortOrder: 'descend',
                                  },
                                  {
                                      title: 'Posledná zmena',
                                      dataIndex: 'modified',
                                      scopedSlots: {customRender: 'datetime'},
                                      onFilter: (value, record) => record.name.indexOf(value) === 0,
                                      sorter: (a, b) => a.modified - b.modified,
                                      defaultSortOrder: 'descend',
                                  },
                                  {
                                      align: 'right',
                                      slots: {'title': 'customTitle'},
                                      scopedSlots: {customRender: 'name'},
                                  }
                              );
                          }


                          this.fieldtitle = vval;
                          this.slugfields = obj.id;
                          this.maincategory = obj.maincategory;

                          if(this.tid !== undefined) this.filt(this.tid,this.maincategory,12)
                      }
if(!this.filterBy)
  this.visible = false;
                  });


              });

          this.fetch();

      },
      created() {
  // Access query parameters
  const query = this.$route.query;

  // Assign query parameters to data properties
  this.id = query.id;
  if (query.acfields) {
  this.acfields = query.acfields.split(',');
  }
  // Parse the comma-separated string into an array
  if (query.nids) {
    this.nids = query.nids.split(',');
  }

  console.log(query);
},
      methods: {

          uploader(editor)
          {
              editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                  return new UploadAdapter( loader );
              };
          },
          assignData()
          {

for (const [key, value] of Object.entries(this.bulkUpdateFields)) {
  if(this.isValueSet(value))
  {
      for (const [keyss, obj] of Object.entries(this.data)) {
          obj[key] = value;
      }
  }
};
          },
          isValueSet(value) {
  return value !== null && value !== undefined && value !== '';
},
          bulkupdate()
          {
dataService.axiosPost('bulkupdate', {nodes: this.data,acfields: this.acfields, ct: this.id}).then(results => {
                      if (results.data.success) {
                          this.$message.success('Obsah bol upravený');
                      }
          });

          },
          fetch(params = {results: this.pagination.pageSize, page: 0, sort: {field:'created',dir: 'descend'}}) {
              this.loading = true;

              function compare( a, b ) {
if ( parseInt(a.weight) < parseInt(b.weight) ){
  return -1;
}
if ( parseInt(a.weight) > parseInt(b.weight) ){
  return 1;
}
return 0;
}

const order = {
field: params.sort.field,
asc: params.sort.order === 'descend' ? false : true
}

dataService.axiosPost("multicontent", {'fields': this.acfields,'nids' : this.nids}).then(results => {
  this.data = results.data.data;
});

          },

          handleTableChange(pagination, filters, sorter) {
   const pager = { ...this.pagination };
   pager.current = pagination.current;
   this.pagination = pager;
   this.fetch({
     results: pagination.pageSize,
     page: pagination.current,
     sort: {field: sorter.field, dir: sorter.order}
   });





 },

     },





  };
</script>

<style>
ul.ant-pagination {
margin-top: 0;
margin-bottom: 1em;
}
.ant-pagination a {
color: #1890ff;
text-decoration: none;
background-color: transparent;
outline: none;
cursor: pointer;
-webkit-transition: color 0.3s;
transition: color 0.3s;
-webkit-text-decoration-skip: objects;
}
.ant-carousel >>> .slick-slide {
text-align: center;
height: 160px;
line-height: 160px;
background: #364d79;
overflow: hidden;
}
.main-content-content .ant-table-thead > tr > th
{
padding:7px 6px;
font-size:12px;
}


.main-content-content .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full
{margin-top:-0.4em}

.main-content-content .ant-table-tbody > tr > td
{
padding:5px 10px;
font-size: .875rem;
}
.reordering-table  .ant-table-tbody > tr > td
{
padding: 8px 16px;
}
#app .smaller .ant-select-selection__rendered,
#app .smaller .ant-select-selection--single
{line-height:40px;height:40px;margin-right:10px;}
.system-options
{width:25%;}
.system-options a,
.system-options button
{float:right;
font-weight: 600;
height:30px;}
.ant-carousel .custom-slick-arrow {
width: 25px;
height: 25px;
line-height: 20px;
font-size: 14px;
color: #fff;
background-color: rgba(31, 45, 61, 0.41);
border-radius: 100%;
opacity: 0.9;
top:43%;
padding: 4px;
padding-left: 8px;
}
.ant-carousel .custom-slick-arrow:before
{display:none}
.ant-carousel .custom-slick-arrow:hover {
opacity: 0.5;
color:#fff;
background: #2d2d2d;
}

.ant-carousel .slick-slide img {
width:100%;
}
.ant-popover-inner-content
{max-width:500px;}
.ant-pagination a:focus {
text-decoration: underline;
-webkit-text-decoration-skip: ink;
text-decoration-skip-ink: auto;
}

.ant-pagination a:hover {
color: #40a9ff;
}
.actions
{display: block;
width: 175px;
float:right;
text-align: right;}

.extra-link
{
  display:inline-block;
}
.square{
display:block;
width:20px;
height:20px;
}
.nopad a
{padding:0;}

.ant-pagination a:active {
color: #096dd9;
}

.ant-pagination a:active, a:hover {
text-decoration: none;
outline: 0;
}

.ant-pagination a {
-ms-touch-action: manipulation;
touch-action: manipulation;
}

.ant-table-pagination.ant-pagination {
margin-bottom: 40px;
margin-right: 20px;
}
.infield-gallery-image
{float:left;
width:50%;
}
.infield-gallery-image img
{width:100%;}
.ant-pagination5-item {
border: 0;
}

.ant-table {
border-bottom: 1px solid rgba(0, 0, 0, 0.05);
margin-bottom: 20px;
}

.ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
-webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
border: 0;
}
.filter-block .remove-filter
{cursor:pointer;}
.filter-block
{background-color: #fafafa;
border-top-right-radius:10px;
margin-bottom:-10px;
position:relative;z-index:99;
border-top-left-radius:10px;
text-align:right;}
.main-content-content .filter-block{
  padding:0!important;
}
.filter-block .d-inline .ant-tag
{margin-top:10px; margin-bottom:10px}
.ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
padding: 0;
margin: 0 3px;
border-radius: 50% !important;
width: 36px;
height: 36px;
font-size: 0.875rem;
}

.ant-pagination5-prev .ant-pagination5-item-link, .ant-pagination5-next .ant-pagination5-item-link {
width: 36px;
height: 36px;
padding: 0;
margin: 0 3px;
font-size: 12px;
}

.ant-pagination5-item.ant-pagination5-item-active a {
z-index: 1;
color: #fff;
background-color: #5e72e4;
border-color: #5e72e4;
}
.quick-links label
{font-size:12px;line-height:35px;}
.extramuted
{border-color: #deebff;font-size:12px;
background-color:#fff;}
.extramuted.selected
{border-color:#5e72e4}
.extramuted:hover
{background-color: #5e72e4;
color:#fff!important;}
.ant-pagination5-item-link.ant-pagination5-disabled a {
color: #8898aa;
pointer-events: none;
cursor: auto;
background-color: #fff;
border-color: #dee2e6;
}

.ant-dropdown-open .btn
{    background-color: #5e72e4;
color:#fff!important;
border-color: #5e72e4;}

</style>
